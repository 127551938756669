// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-default-service-js": () => import("/opt/build/repo/src/templates/default-service.js" /* webpackChunkName: "component---src-templates-default-service-js" */),
  "component---src-templates-page-about-js": () => import("/opt/build/repo/src/templates/page-about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-service-js": () => import("/opt/build/repo/src/templates/page-service.js" /* webpackChunkName: "component---src-templates-page-service-js" */),
  "component---src-templates-page-contact-js": () => import("/opt/build/repo/src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-product-js": () => import("/opt/build/repo/src/templates/page-product.js" /* webpackChunkName: "component---src-templates-page-product-js" */),
  "component---src-templates-default-page-js": () => import("/opt/build/repo/src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

